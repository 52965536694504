import React, { useEffect, useMemo } from 'react';
import { useGetCatalogueApps } from '../../customHooks/apps/useApps';
import { AppModel } from '../../models/app';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IframePage from '../Iframe/IframePage';
import { Spin } from 'maui';
import { decodeString, useUrlParams } from 'findem-helpers';

const InstalledApp: React.FC = () => {
  const { id: paramId } = useParams<{ id?: string }>();
  const {url} = useUrlParams();
  const [urlFromState, setUrlFromState] = React.useState<string | null>(null);
  const [isURLInitialized, setIsURLInitialized] = React.useState<boolean>(false);
  
  const [launchedApp, setLaunchedApp] = React.useState<AppModel | null>(null);

  const iframeUrl = useMemo(() => {
    if(url){
      const path= window.location.href.split('url=').at(-1);
      const isStaging = window.location.hostname.includes('dev')
      return isStaging ? `https://rra-staging.findem.ai${path}` : `https://rra-app.findem.ai${path}`;
    }
    return launchedApp?.getValue().launchUrl;
  }, [launchedApp, url]);

  const { isLoading, apps } = useGetCatalogueApps();

  useEffect(() => {
    if(apps && paramId){
      const app = apps.find((app: AppModel) => app.getValue().id === paramId);
      if(app){
        setLaunchedApp(app);
      }
    }
  },[apps, paramId]);

  // add post message listener to handle sidebar collapse
  React.useLayoutEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data?.type === 'searchPageMsg' || event.data?.type === 'searchesPageMsg') {
        // add url query param to main page
        const {pathName} = event.data;
        if(pathName){
          const url = new URL(`${window.location.origin}${window.location.pathname}`);
          url.searchParams.set('url', decodeString(pathName));
          window.history.pushState({}, '', decodeString(url.toString()));
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if(iframeUrl && !isURLInitialized){
      setUrlFromState(iframeUrl);
      setIsURLInitialized(true);
    }
  }, [iframeUrl, isURLInitialized]);

  return (<>
    {!isLoading && urlFromState && <IframePage iframeRoute={urlFromState} />}
    {isLoading && <div><Spin/></div>}
  </>);
}

export default InstalledApp;