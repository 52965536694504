import { useUrlParams } from "findem-helpers";
import { useRef } from "react";
import styled from "styled-components";

interface IIframePageProps {
  iframeRoute: string;
}

const IframePage: React.FC<IIframePageProps> = ({ iframeRoute }) => {
  const { features } = useUrlParams();
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const paramString = iframeRoute.split('?')[1];
  const queryString = new URLSearchParams(paramString);
  const urlParamsLength = queryString.size;
  console.log('urlParamsLength', urlParamsLength);
  return (
    <IframeWrapper 
      ref={iFrameRef} 
      src={`${iframeRoute}${urlParamsLength ? '&' : '?'}${!iframeRoute.includes('iframe') ? 
      `iframe=true` : ''
      }${features ? `&features=${features}` : ''}`}
      allow='clipboard-read; clipboard-write'
    />
  );
}

export default IframePage;

export const IframeWrapper = styled.iframe`
  border: none;
  height: ${({ height }) => height ? height : 'calc(100vh - 64px)'};
`;


